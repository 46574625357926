<template>
  <div class="app-wrap">
    <div class="main">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
* {
  margin: 0;
  padding: 0;
}

.app-wrap {
  flex: 1 1 auto;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;

  .main {
    padding: 0px 0px 0px 0px;
    display: flex;
    flex: 1 0 auto;
    max-width: 100%;
    height: calc(100vh - 0px);
    overflow: hidden;
  }
}
</style>
